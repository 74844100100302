
$yellow: #f8f13d;
$darkred: darken(#4c0000, 8%);
$green: darken(#37bb70, 10%);

$grey: #1c2427;

$blue: #21293C;
$darkblue: #305C9E;
$red: #ED3833;
$lightblue: #1181C8;
$lightgrey: #C0BDD5;

$title_font: "Source Sans 3";

$siteWideSM: 520px;
$siteWideMD: 658px;
$siteWideLG: 850px;
$siteWideXL: 1200px;


.black{ color: black; }
.black-20{ color: rgba(black, 0.2); }
.black-50{ color: rgba(black, 0.5); }
.black-80{ color: rgba(black, 0.8); }
.white{color: $lightblue;}
.blue{color: $blue !important;}

strong.white > a {
	color: $lightblue;
}