html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 17px;
  line-height: 1.4em;
}

input:focus, button:focus{outline: 0;}

.footnote {
  font-size: 0.93rem;
  opacity: 0.9;
  margin-top: 5px;
}