.button{
	display: inline-block;
	font-size: 1rem;
    font-weight: 600;
    padding: 5px 2rem;
    text-align: center;
    border:2px solid transparent;
    border-radius: 30px;
    line-height: 2;
    transition: 0.3s;
    min-width: 160px;
    margin: 0 10px 1rem 10px;
}
.button:hover{
	text-decoration: none;
}

.button--disabled {
  opacity: 0.35;
  cursor: default;
}

.button--disabled:hover {
  opacity: 0.1;
}

.button--small{
	padding: 0px 1.5rem;
    min-width: unset;
    font-size: 0.9rem;
    margin: 0 0 1rem 0;
}

.button--big {	
	padding: 10px 2.2rem;
    min-width: 300px;
    font-size: 1.1rem;
    margin:  0 15px 2rem 15px;
}

.button--grey{
	background-color: $grey !important;
	color: white;
	&:hover{
		border:2px solid $grey;
		background-color: white !important;
		color: $grey;
	}
}

.button--red{
	background-color: $red !important;
	color: white;
	&:hover, &.selected{
		border:2px solid $red;
		background-color: white !important;
		color: $red;
	}
}

.button--yellow{
	background-color: $yellow !important;
	color: $blue;
	&:hover, &.selected{
		border:2px solid $yellow;
		background-color: transparent !important;
		color: $yellow;
	}
}

.button--green{
	background-color: $green !important;
	color: white;
	&:hover, &.selected{
		border:2px solid $green;
		background-color: white !important;
		color: $green;
	}
}

.button--blue{
	background-color: $blue !important;
	color: $yellow;
	&:hover, &.selected{
		border:2px solid $blue;
		background-color: $yellow !important;
		color: $blue;
	}
}

.button--darkblue{
	background-color: $darkblue !important;
	color: $yellow;
	&:hover, &.selected{
		border:2px solid $darkblue;
		background-color: $yellow !important;
		color: $darkblue;
	}
}

.button--lightblue{
	background-color: $lightblue !important;
	color: $darkblue;
	&:hover, &.selected{
		border:2px solid $lightblue;
		background-color: $darkblue !important;
		color: $lightblue;
	}
}

.button--black-transparent{
	background-color: transparent;
	color: black;
	&:hover, &.selected{
		border-color:black;
		color: black;
	}
}

.button--white-transparent{
	background-color: transparent;
	color: white;
	&:hover, &.selected{
		border-color:white;
		color: white;
	}
}

.button--white{
	background-color: white;
	color: black;
	&:hover, &.selected{
		border:2px solid white;
		background-color: transparent !important;
		color: white !important;
	}
}