.BannerCity{
	color: white;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 2rem;
}

.BannerCity__sub{
	color: rgba($lightblue, 0.6);
	font-weight: 600;
    font-size: 1.5rem;
}
.BannerCity__name{
	color: $lightblue;
	text-transform: uppercase;
	font-family: $title_font;
    font-size: 2.5rem;
    line-height: 1.3;
    margin-bottom: 1rem;
}
.BannerCity__location {
	 color: rgba(white, 0.6);
    font-size: 1.2rem;
	font-weight: 600;
    line-height: 2;
}

.BannerCity__partner {
	color: rgba(white, 0.6);
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2;
}

.BannerCity__logo {
	height: 64px;
	text-align: center;
	margin-bottom: 1rem;
}

@media (min-width: 1200px){
	.BannerCity__logo {
		position: absolute;
		height: 150px;
		margin-right: 25px;
	}

	.BannerCity__logo a {
		line-height: 150px;
	}
}

.BannerCity__logo img {
	max-width: 200px;
	max-height: 100%;
	background: white;
}

.BannerCity .button{
	background-color: white;
	padding: 2px 2rem;
	min-width: unset;
	margin-top: 2rem;
	margin-right: 10px;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}