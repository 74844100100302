.Concept {
	background-color: $blue;
	min-height: 450px;
    background-position: right bottom;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    padding: 4rem 15px;
    color: white;

    @include mobile{
    	background-image: unset;
    }
}

.Concept2 {
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: auto 70%;
    margin-bottom: 10px;
    padding: 4rem 15px;
    color: white;

    @include mobile{
    	background-image: unset;
    }
}

.Concept .sectionTitle .sub{
     color: rgba($lightblue, 0.6);
}
.Concept.bgyellow .sectionTitle .sub{
     color: rgba($darkblue, 0.6);
}
.Concept .text{ max-width:500px; }