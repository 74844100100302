.sectionTitle{
	margin-bottom: 2rem;
}
.sectionTitle.medium{
	.big{font-size: 1.6em;}
}

.sectionTitle .icon{
	width: 50px;
	margin-bottom: 1rem;
}
.sectionTitle .sub{
	font-weight: 600;
}
.sectionTitle .big{
	font-family: 'Source Sans 3', sans-serif;
/*	text-transform: uppercase;*/
	font-weight: 600;
	font-size: 3em;
	margin-bottom: 1.5rem;
	line-height: 1.5;
	@include mobile{font-size: 2em;}
}

.sectionTitle .sub-inner{
	font-weight: 600;
	font-size: 1.3rem;
}

.sectionTitle .sub-big{
	font-weight: 600;
    font-size: 1.2rem;
}

.sectionTitle.red{
	.icon,.sub-inner{color: $red;}
}
.sectionTitle.yellow{
	.icon,.sub-inner{color: $yellow;}
}
.sectionTitle.green{
	.icon,.sub-inner{color: $green;}
}
.sectionTitle.blue{
	.icon,.sub-inner{color: $blue;}
}
.sectionTitle.white{
	color: white;
	.sub{color: rgba(white, 0.8);}
}


@media(max-width: 1600px){
  .sectionTitle.medium{
	.big{font-size: 1.5em;}
	.medium{font-size: 1.3em;}
  }
  .sectionTitle .big{
	font-size: 2.2em;
  }
}