.IcoText{
	display: inline-block;
	font-size: 0.8rem;
	color: rgba($lightblue, 0.8);
	font-weight: 600;
	text-align: center;
	margin: 0 0 2rem 0;
    width: 160px;
    vertical-align: top;
}
.bgyellow .IcoText,
.bglightblue .IcoText {
	color: rgba($darkblue, 0.8);
}
.IcoText .icon{
	margin-bottom: 0.5rem;
	height: 45px;
}