.BandeauDiagonals{
	position: relative;
    min-height: 470px;
    margin-bottom: 10px;
    padding: 3rem 2rem;
    color: white;
}

.BandeauDiagonals{
	&.bggreen{background-color: $green;}
	&.bgyellow{background-color: $yellow;}
	&.bgred{background-color: $red;}
	&.iconcircle,
	&.iconstar {
		background-position: right bottom;
		background-size: 15%;
		background-repeat: no-repeat;
		@media (min-width: 1200px){
			background-position: center bottom;
			background-size: 8%;
			&.right {
				background-position: center top;
			}
		}
	}
}
.BandeauDiagonals .layer1{
	position: absolute;
	top: 0; left: 0; bottom: 0;
	z-index: 1;
	width: 24%;
	min-height: 470px;
	overflow: hidden;
}
.BandeauDiagonals .layer1 .bg{
	position: absolute;
	top: 0; left: 0; bottom: 0;right: 0;
	background-size: cover;
	background-position: center center;
}
.BandeauDiagonals .layer2{
	position: absolute;
	z-index: 2;
	top: 0; left: 14%; bottom: 0;
	overflow: hidden;
	border-left: 8px solid white;
	border-right: 8px solid white;
	transform: skewX(-18deg);
	width: 20%;
	min-height: 470px;
}
.BandeauDiagonals .layer2 .bg{
	position: absolute;
	top: 0; left: -100px; bottom: 0;right: -50px;
	background-size: cover;
	background-position: center center;
	transform: skewX(18deg);
}

.BandeauDiagonals .layer3{
	position: absolute;
	z-index: 3;
	top: 0; left: 30%; bottom: 0;
	overflow: hidden;
	border-left: 8px solid white;
	border-right: 8px solid white;
	transform: skewX(-18deg);
	width: 16%;
	min-height: 470px;
}
.BandeauDiagonals .layer3 .bg{
	position: absolute;
	top: 0; left: -100px; bottom: 0;right: -100px;
	background-size: cover;
	background-position: center center;
	transform: skewX(18deg) ;
}

.BandeauDiagonals__content{
	position: relative;
	z-index: 5;
	@media (min-width: 1200px){
	  width: 45%;
      &.right {
	    width: 50%;
      }
	}
	max-width: 650px;
	@include xl{ margin-left: auto; }
}

@media (min-width: 1200px){
  .right .BandeauDiagonals__content {
    width: 50%;
  }
}


.BandeauDiagonals.right{
	.layer1{left: unset; right: 0;}
	.layer2{left: unset;right: 14%;}
	.layer3{left: unset; right: 30%;}

	.layer2 .bg{left: -80px; bottom: 0;right: -100px;}
	.layer3 .bg{left: -115px; bottom: 0;right: -115px;}
	.BandeauDiagonals__content{margin-left: unset;}
}





.BandeauDiagonals{
	@media (max-width: 1199px){
		min-height: 300px;
		.layer1,.layer2,.layer3{display: none;}
	}
}