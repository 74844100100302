.Newsletter{
	margin-bottom: 10px;
}

.Newsletter__bg{
	background-color: $green;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right center;
	min-height: 350px;
	color: white;
	font-weight: 400;

	display: flex;
	align-items: center;
}

.Newsletter__content .text{
	max-width: 600px;
}

.Newsletter__content input:not([type='checkbox']){
	display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 2rem;
    border: 2px solid transparent;
    border-radius: 30px;
    margin: 0 5px 1rem 5px;
}

.Newsletter__content input:not([type='checkbox']):focus{
	border-color: $red;
}