.Programme{

}

.SideImage {
	display: none;
	min-height: 350px;
	background-size: cover;
	background-position: center;
}

@media (min-width: 992px){
	.SideImage {
		display: block;
	}
}

.Programme__left{
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 300px;
	padding: 2rem;
	background-size: cover;
	background-position: center;
}

.Programme__left .text{
	margin-bottom: 3rem;
}
.Programme__left .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
}
.Programme__left .overlay.blue{
	background-color: rgba($blue, 0.8);
}

.Programme__left .content{
	position: relative;
}

.Programme__right{
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 2rem;
}