.textgreen {
	color: $green !important;
}
.textyellow {
	color: $yellow !important;
}
.textred {
	color: $red !important;
}
.textblue {
	color: $blue !important;
}
.textlightblue {
	color: $lightblue !important;
}
.textdarkblue {
	color: $darkblue !important;
}
.textwhite {
	color: white !important;
}
.textblack {
	color: black !important;
}
.textblack-80 {
	color: rgba(black, 0.8) !important;
}
.textgrey {
	color: $grey !important;
}

a.textwhite,
a.textblue,
a.textdarkblue,
a.textyellow,
a.textlightblue {
	text-decoration: underline;
}
a.textgreen:hover {
	color: darken($green, 30%);
}
a.textyellow:hover {
	color: darken($yellow, 30%);
}
a.textred:hover {
	color: darken($red, 30%);
}
a.textblue:hover {
	color: darken($blue, 30%);
}
a.textlightblue:hover {
	color: darken($lightblue, 30%);
}
a.textdarkblue:hover {
	color: darken($darkblue, 30%);
}
a.textwhite:hover {
	color: white;
}
a.textblack:hover {
	color: black;
}

.bggreen {
	background-color: $green !important;
}
.bgyellow {
	background-color: $yellow !important;
	color: $darkblue !important;
}
.bgred {
	background-color: $red !important;
}
.bgblue {
	background-color: $blue !important;
	color: $lightblue !important;
}
.bgblue-80 {
	background-color: rgba($blue, 0.8) !important;
}
.bgblue-50 {
	background-color: rgba($blue, 0.5) !important;
}
.bgdarkblue {
	background-color: $darkblue !important;
	color: $lightblue !important;
}
.bglightblue {
	background-color: $lightblue !important;
	color: $darkblue !important;
}
.bgwhite {
	background-color: white;
}
.bgwhite-80 {
	background-color: rgba(white, 0.8);
}
.bgwhite-50 {
	background-color: rgba(white, 0.5);
}
.bgwhite-20 {
	background-color: rgba(white, 0.2);
}
.bgblack {
	background-color: black;
}
.bgblack-50 {
	background-color: rgba(black, 0.5);
}
.bgblack-80 {
	background-color: rgba(black, 0.8);
}
.bgblack-20 {
	background-color: rgba(black, 0.2);
}
.bggrey {
	background-color: $lightgrey;
}
.bgdarkgrey {
	background-color: #444444;
}
.bgimage {
	background-size: cover;
	background-position: center;
}

.outlinegreen {
	border: 5px solid $green;
}
.outlineyellow {
	border: 5px solid $yellow;
}
.outlinered {
	border: 5px solid $red;
}
.outlineblue {
	border: 5px solid $blue;
}
.outlinedarkblue {
	border: 5px solid $darkblue;
}
.outlinelightblue {
	border: 5px solid $lightblue;
}
.outlinewhite {
	border: 5px solid white;
}
.outlineblack {
	border: 5px solid black;
}

.table1.bordered-table {
	display: none;
}

.bordered-table .row > div {
	border-style: solid;
	border-width: 0 16px 6px 0;
	border-color: $darkblue;
}

.bgwhite.bordered-table .row > div {
	border-color: white;
}

.bordered-table.border-y-0 .row > div {
	border-bottom-width: 0;
}

@media (max-width: 767px) {
	.table0.bordered-table .row > div:last-child {
		display: none;
	}

	.table1.bordered-table .row > div:first-child {
		display: none;
	}

	.table1.bordered-table {
		display: block;
	}

	.bordered-table .row > div {
		border-left-width: 16px;
	}
}

.bordered-table .row:first-child > div {
	border-top-width: 6px;
}

.bordered-table .row > div:first-child {
	border-left-width: 16px;
}

.white-50 {
  color: rgba(255, 255, 255, 0.6);
}

.lightblue-50 {
  color: rgba($lightblue, 0.6);
}

.darkblue-50 {
  color: rgba($darkblue, 0.6);
}

.yellow-50 {
  color: rgba($yellow, 0.6);
}

.black{
  color: #222222;
}
.black-20 {
   color: rgba(black, 0.3);
}
.black-50 {
   color: rgba(black, 0.6);
}
.white {
  color: white;
}
strong.white > a {
	color: white;
}

.green {
	color: $green !important;
}

.yellow {
	color: $yellow !important;
}

.red {
	color: $red !important;
}

.blue,
strong.textblue > a {
	color: $blue !important;
}

.darkblue,
strong.textdarkblue > a {
	color: $darkblue !important;
}

.lightblue,
strong.textlightblue > a {
	color: $lightblue !important;
}

.dropdown-item.active,.dropdown-item:active{
  background-color: $lightblue;
  color: $darkblue;
}